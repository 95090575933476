import Web3 from "web3";
import big from "big.js";
import {ethers} from "ethers";
import i18n from "../utils/i18n";
// tb合约pg合约方法
export default {
  // 用户信息
  async users(currentAddress, ContractAddress, _decimals) {
    try {

      let Factory;
      Factory = require("../json/abi/polygon.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.users(currentAddress)
      console.log(balance)
      // ethers转json
      let data = balance
      let newData = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (typeof data[key] === 'object' && data[key]._isBigNumber) {
            newData[key] = data[key].toString();
          } else {
            newData[key] = data[key];
          }
        }
      }


      newData.investAmount = big(newData.investAmount)
        .div(10 ** 18)
        .toFixed();
      newData.rewardAmount = big(newData.rewardAmount)
        .div(10 ** 18)
        .toFixed();
      newData.teamInvestAmount = big(newData.teamInvestAmount)
        .div(10 ** 18)
        .toFixed();


      return {code: "0", data: newData};
    } catch (error) {
      //  console.log(error);
    }
  },
  async getUserLookInfo(currentAddress, ContractAddress, _decimals) {
    try {

      let Factory;
      Factory = require("../json/abi/polygon.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.getUserLookInfo(currentAddress)
      console.log(balance)
      // ethers转json
      let data = balance
      let newData = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (typeof data[key] === 'object' && data[key]._isBigNumber) {
            newData[key] = data[key].toString();
          } else {
            newData[key] = data[key];
          }
        }
      }


      return {code: "0", data: newData};
    } catch (error) {
      console.log(error);
    }
  },
  async burnRules(currentAddress, ContractAddress, _decimals, token, value) {
    try {

      let Factory;
      Factory = require("../json/abi/polygon.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.burnRules(token, value)
      console.log(balance)
      // ethers转json
      let data = balance
      let newData = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (typeof data[key] === 'object' && data[key]._isBigNumber) {
            newData[key] = data[key].toString();
          } else {
            newData[key] = data[key];
          }
        }
      }

      newData.investAmount = big(newData.investAmount)
        .div(10 ** 18)
        .toFixed();
      return {code: "0", data: newData};
    } catch (error) {
      console.log(error);
    }
  },
  async burnContractAddress(currentAddress, ContractAddress, _decimals) {
    try {

      let Factory;
      Factory = require("../json/abi/polygon.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.childContractAddress()
      console.log(balance)

      return {code: "0", data: balance};
    } catch (error) {
      console.log(error);
    }
  },
  async pickUsers(currentAddress, ContractAddress, _decimals) {
    try {

      let Factory;
      Factory = require("../json/abi/polygon.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.pickUsers(currentAddress)
      console.log(balance)
      // ethers转json
      let data = balance
      let newData = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (typeof data[key] === 'object' && data[key]._isBigNumber) {
            newData[key] = data[key].toString();
          } else {
            newData[key] = data[key];
          }
        }
      }
      newData.usdtAmount = big(newData.usdtAmount)
        .div(10 ** 18)
        .toFixed();


      return {code: "0", data: newData};
    } catch (error) {
      console.log(error);
    }
  },
  //
  async nowDepositWithdrawToken(currentAddress, ContractAddress, _decimals, swap_Aaddress) {
    try {
      console.log(currentAddress, 'swap_Aaddressswap_Aaddress')
      let Factory;
      Factory = require("../json/abi/polygon.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.nowDepositWithdrawToken()
      console.log(balance, 'nowDepositWithdrawTokennowDepositWithdrawTokennowDepositWithdrawToken')
      // ethers转json
      // var datas
      // datas = big(balance.toString())
      //   .div(10 ** 18)
      //   .toFixed();

      return {code: "0", data: balance};
    } catch (error) {
      console.log(error);
    }
  },
  async pickGetMyUserFalcon(currentAddress, ContractAddress, _decimals) {
    try {

      let Factory;
      Factory = require("../json/abi/polygon.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.pickGetMyUserFalcon(currentAddress)
      console.log(balance, 'pickGetMyUserFalcon')
      // ethers转json
      var datas
      datas = big(balance.toString())
        .div(10 ** 18)
        .toFixed();

      return {code: "0", data: datas};
    } catch (error) {
      console.log(error);
    }
  },
  async pickGetMyUserDaiInterest(currentAddress, ContractAddress, _decimals) {
    try {

      let Factory;
      Factory = require("../json/abi/polygon.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.pickGetMyUserDaiInterest(currentAddress)
      // ethers转json
      var datas
      datas = big(balance.toString())
        .div(10 ** 18)
        .toFixed();

      return {code: "0", data: datas};
    } catch (error) {
      console.log(error);
    }
  },
  async tokenUsers(currentAddress, ContractAddress, _decimals, swap_Aaddress) {
    try {

      let Factory;
      Factory = require("../json/abi/polygon.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.tokenUsers(swap_Aaddress, currentAddress)
      console.log(balance, 'tokenUsers')
      // ethers转json
      let data = balance
      let newData = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (typeof data[key] === 'object' && data[key]._isBigNumber) {
            newData[key] = data[key].toString();
          } else {
            newData[key] = data[key];
          }
        }
      }

      newData.investAmount = big(newData.investAmount)
        .div(10 ** 18)
        .toFixed();
      newData.rewardAmount = big(newData.rewardAmount)
        .div(10 ** 18)
        .toFixed();
      newData.fInvestAmount = big(newData.fInvestAmount)
        .div(10 ** 18)
        .toFixed();
      return {code: "0", data: newData};
    } catch (error) {
      console.log(error);
    }
  },
  async userIsActive(currentAddress, ContractAddress, _decimals) {
    try {
      console.log(currentAddress, 'swap_Aaddressswap_Aaddress')
      let Factory;
      Factory = require("../json/abi/polygon.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.userIsActive(currentAddress)
      console.log(balance, 'userIsActive')
      // ethers转json


      return {code: "0", data: balance};
    } catch (error) {
      console.log(error);
    }
  },
  async hasActivate(currentAddress, ContractAddress, _decimals) {
    try {

      let Factory;
      Factory = require("../json/abi/polygon.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.hasActivate()
      console.log(balance, 'hasActivate')
      // ethers转json
      var datas
      datas = big(balance.toString())
        .div(10 ** 18)
        .toFixed();

      return {code: "0", data: datas};
    } catch (error) {
      console.log(error);
    }
  },
  // 已投资
  async allInvestAmount(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/polygon.json");

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例

      const balance = await contract.allInvestAmount()


      var datas
      datas = big(balance.toString())
        .div(10 ** 18)
        .toFixed();

      return {code: "0", data: datas};
    } catch (error) {
      // console.log(error);
    }
  },
  // 需要投资
  async limitAllInvestAmount(currentAddress, ContractAddress, _decimals) {
    try {

      let Factory;
      Factory = require("../json/abi/polygon.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例

      const balance = await contract.limitAllInvestAmount()


      // datas = parameter;
      var datas
      datas = big(balance.toString())
        .div(10 ** 18)
        .toFixed();
      return {code: "0", data: datas};
    } catch (error) {
      //  console.log(error);
    }
  },
  // 最小投入总数
  async minInvestAmount(currentAddress, ContractAddress, _decimals) {
    try {

      let Factory;
      Factory = require("../json/abi/polygon.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例

      const balance = await contract.minInvestAmount()


      var datas
      datas = big(balance.toString())
        .div(10 ** 18)
        .toFixed();
      return {code: "0", data: datas};
    } catch (error) {
      //  console.log(error);
    }
  },
  // 个人收益

  async getMyUserInterest(currentAddress, ContractAddress, _decimals, swap_Aaddress) {
    try {

      let Factory;
      Factory = require("../json/abi/polygon.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例

      const balance = await contract.getMyUserInterest(swap_Aaddress, currentAddress)

      var datas
      datas = big(balance.toString())
        .div(10 ** 18)
        .toFixed();

      return {code: "0", data: datas};
    } catch (error) {
      console.log('error');
    }
  },
  async burnGetMyUserBurnInterest(currentAddress, ContractAddress, _decimals, swap_Aaddress, typeid) {
    try {

      let Factory;
      Factory = require("../json/abi/polygon.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例

      const balance = await contract.burnGetMyUserBurnInterest(swap_Aaddress, currentAddress, typeid)

      let data = balance
      let newData = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (typeof data[key] === 'object' && data[key]._isBigNumber) {
            newData[key] = data[key].toString();
          } else {
            newData[key] = data[key];
          }
        }
      }
      //console.log(newData,"dcfsdfsdbgsdfdgdf");
      newData.totalInterest = big(newData.totalInterest)
        .div(10 ** 18)
        .toFixed();
      newData.withdrawableInterest = big(newData.withdrawableInterest)
        .div(10 ** 18)
        .toFixed();

      return {code: "0", data: newData};
    } catch (error) {
      console.log('error');
    }
  },
  async burnUsers(currentAddress, ContractAddress, _decimals, swap_Aaddress) {
    try {

      let Factory;
      Factory = require("../json/abi/polygon.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例

      const balance = await contract.burnUsers(swap_Aaddress, currentAddress)

      let data = balance
      let newData = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (typeof data[key] === 'object' && data[key]._isBigNumber) {
            newData[key] = data[key].toString();
          } else {
            newData[key] = data[key];
          }
        }
      }

      newData.burnAmount = big(newData.burnAmount)
        .div(10 ** 18)
        .toFixed();

      newData.interestAmount = big(newData.interestAmount)
        .div(10 ** 18)
        .toFixed();

      newData.falconAmount = big(newData.falconAmount)
        .div(10 ** 18)
        .toFixed();

      newData.usdtInterestAmount = big(newData.usdtInterestAmount)
        .div(10 ** 18)
        .toFixed();
      return {code: "0", data: newData};
    } catch (error) {
      console.log('error');
    }
  },
  async burnGetMinFalconToken(currentAddress, ContractAddress, _decimals, swap_Aaddress, slidervalue) {
    try {
      console.log(swap_Aaddress, currentAddress, slidervalue)
      let Factory;
      Factory = require("../json/abi/polygon.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例

      const balance = await contract.burnGetMinFalconToken(swap_Aaddress, currentAddress, slidervalue)

      let data = balance
      let newData = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (typeof data[key] === 'object' && data[key]._isBigNumber) {
            newData[key] = data[key].toString();
          } else {
            newData[key] = data[key];
          }
        }
      }
      newData[1] = big(newData[1])
        .div(10 ** 18)
        .toFixed();
      return {code: "0", data: newData};
    } catch (error) {
      console.log(error, 'error');
    }
  },
  // 存入
  async deposit(tokenAddress, amountADesired, CurrentAccount, _decimals) {
    try {

      let Factory;
      Factory = require("../json/abi/polygon.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);


      const amountApproved = big(amountADesired)
        .times(10 ** _decimals)
        .toFixed();

      const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);

      const approveFunction = tokebContract.methods.deposit(amountApproved); // 替换为您要授权的代币数量

      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      const hexValue = await approveFunction.estimateGas({
        from: CurrentAccount,
        to: tokenAddress,
        data: approveFunction.encodeABI(),
      });


      await tokebContract.methods
        .deposit(amountApproved)
        .send({
          from: CurrentAccount,
          //to: tokenAddress,
          data: approveFunction.encodeABI(),
          //gasLimit: hexValue,
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 1.5),
        });

      return {code: "0"};
    } catch (error) {
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }

      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = "Node error! Please change the node.";
      }

      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }

      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };


    }
  },
  // 存入2
  async depositToken(tokenAddress, amountADesired, CurrentAccount, _decimals, swap_Aaddress) {
    try {

      let Factory;
      Factory = require("../json/abi/polygon.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);


      const amountApproved = big(amountADesired)
        .times(10 ** _decimals)
        .toFixed();

      const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);

      const approveFunction = tokebContract.methods.depositToken(swap_Aaddress, amountApproved); // 替换为您要授权的代币数量

      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      const hexValue = await approveFunction.estimateGas({
        from: CurrentAccount,
        to: tokenAddress,
        data: approveFunction.encodeABI(),
      });


      await tokebContract.methods
        .depositToken(swap_Aaddress, amountApproved)
        .send({
          from: CurrentAccount,
          //to: tokenAddress,
          data: approveFunction.encodeABI(),
          //gasLimit: hexValue,
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 1.5),
        });

      return {code: "0"};
    } catch (error) {
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }

      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = "Node error! Please change the node.";
      }

      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }

      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };


    }
  },
  // 自动
  async pickDeposit(
    addresscode,
    ContractAddress,
    _decimals,
    usdt,
    falcon,
  ) {
    try {
      //   let position = "";
      //  console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/polygon.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      // 创建合约对象
      //    console.log(Factory.abi)


      //   console.log(amountApproved)
      const tokebContract = new web3.eth.Contract(Factory.abi, ContractAddress);
      const usdtvalue = big(usdt)
        .times(10 ** 6)
        .toFixed();
      // console.log(tokebContract)
      const approveFunction = tokebContract.methods.pickDeposit(usdtvalue, web3.utils.toWei(falcon, 'ether')); // 替换为您要授权的代币数量
      //  console.log(approveFunction)
      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      //  预估
      var hexValuedata = {}
      hexValuedata = {
        from: addresscode,
        to: ContractAddress,
        data: approveFunction.encodeABI(),
      }
      const hexValue = await approveFunction.estimateGas(hexValuedata);
      console.log(hexValue, '1111111111111111');
      const hexValueto = hexValue.toString();

      console.log(Math.round(hexValueto * 1.5), '22222222222222222')
      var senddata = {}
      senddata = {
        from: addresscode,
        gasLimit: Math.round(hexValueto * 1.5),
        to: ContractAddress,
        data: approveFunction.encodeABI(),
        gas: hexValue,
        gasPrice: Math.round(getGasPriceto * 1.5),
      }
      await tokebContract.methods
        .pickDeposit(usdtvalue, web3.utils.toWei(falcon, 'ether'))
        .send(senddata);

      return {code: "0"};
    } catch (error) {
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }

      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = i18n.t("ido.message");
      }

      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }

      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };
      //    console.log(error);
    }
  },
  async pickWithdraw(
    addresscode,
    ContractAddress,
    _decimals,
    usdt,
    falcon,
    _usdtInterestAmount,
  ) {
    try {
      //   let position = "";
      //  console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/polygon.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      // 创建合约对象
      //    console.log(Factory.abi)


      //   console.log(amountApproved)
      const tokebContract = new web3.eth.Contract(Factory.abi, ContractAddress);
      const usdtvalue = big(usdt)
        .times(10 ** 6)
        .toFixed();
      const usdtInterestAmount = big(_usdtInterestAmount)
        .times(10 ** 6)
        .toFixed();


      // console.log(tokebContract)
      const approveFunction = tokebContract.methods.pickWithdraw(usdtvalue, usdtInterestAmount, web3.utils.toWei(falcon, 'ether')); // 替换为您要授权的代币数量
      //  console.log(approveFunction)
      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      //  预估
      var hexValuedata = {}
      hexValuedata = {
        from: addresscode,
        to: ContractAddress,
        data: approveFunction.encodeABI(),
      }
      const hexValue = await approveFunction.estimateGas(hexValuedata);
      console.log(hexValue, '1111111111111111');
      const hexValueto = hexValue.toString();

      console.log(Math.round(hexValueto * 1.5), '22222222222222222')
      var senddata = {}
      senddata = {
        from: addresscode,
        gasLimit: Math.round(hexValueto * 1.5),
        to: ContractAddress,
        data: approveFunction.encodeABI(),
        gas: hexValue,
        gasPrice: Math.round(getGasPriceto * 1.5),
      }
      await tokebContract.methods
        .pickWithdraw(usdtvalue, usdtInterestAmount, web3.utils.toWei(falcon, 'ether'))
        .send(senddata);

      return {code: "0"};
    } catch (error) {
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }

      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = i18n.t("ido.message");
      }

      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }
      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };
      //    console.log(error);
    }
  },
  // 激活
  async doActivate(tokenAddress, amountADesired, CurrentAccount, _decimals) {
    try {

      let Factory;
      Factory = require("../json/abi/polygon.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);


      // const amountApproved = big(amountADesired)
      //   .times(10 ** _decimals)
      //   .toFixed();

      const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);

      const approveFunction = tokebContract.methods.doActivate(); // 替换为您要授权的代币数量

      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      const hexValue = await approveFunction.estimateGas({
        from: CurrentAccount,
        to: tokenAddress,
        data: approveFunction.encodeABI(),
      });


      await tokebContract.methods
        .doActivate()
        .send({
          from: CurrentAccount,
          //to: tokenAddress,
          data: approveFunction.encodeABI(),
          //gasLimit: hexValue,
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 1.5),
        });

      return {code: "0"};
    } catch (error) {
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }

      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = "Node error! Please change the node.";
      }

      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }

      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };


    }
  },
  // 提取
  async withdraw(tokenAddress, amountADesired, CurrentAccount, _decimals) {
    try {
      //   let position = "";
      //     console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/polygon.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      // 创建合约对象
      //        console.log(Factory.abi)

      const amountApproved = big(amountADesired)
        .times(10 ** _decimals)
        .toFixed();
      //   console.log(amountApproved)
      const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);
      // console.log(tokenAddress)
      // console.log(tokebContract)
      const approveFunction = tokebContract.methods.withdraw(amountApproved); // 替换为您要授权的代币数量
      //  console.log(approveFunction)
      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      //  预估
      const hexValue = await approveFunction.estimateGas({
        from: CurrentAccount,
        to: tokenAddress,
        data: approveFunction.encodeABI(),
      });
      //  console.log(hexValue);

      await tokebContract.methods
        .withdraw(amountApproved)
        .send({
          from: CurrentAccount,
          //gasLimit: hexValue,
          //to: tokenAddress,
          data: approveFunction.encodeABI(),
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 1.5),
        });

      return {code: "0"};
    } catch (error) {
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }

      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = i18n.t("ido.message");
      }
      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }

      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };
    }
  },
  // 提取2
  async withdrawToken(tokenAddress, amountADesired, CurrentAccount, _decimals, swap_Aaddress) {
    try {
      //   let position = "";
      //     console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/polygon.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      // 创建合约对象
      //        console.log(Factory.abi)

      const amountApproved = big(amountADesired)
        .times(10 ** _decimals)
        .toFixed();
      //   console.log(amountApproved)
      const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);
      console.log(tokenAddress)
      // console.log(tokebContract)
      const approveFunction = tokebContract.methods.withdrawToken(swap_Aaddress, amountApproved); // 替换为您要授权的代币数量
      //  console.log(approveFunction)
      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      //  预估
      const hexValue = await approveFunction.estimateGas({
        from: CurrentAccount,
        to: tokenAddress,
        data: approveFunction.encodeABI(),
      });
      //  console.log(hexValue);

      await tokebContract.methods
        .withdrawToken(swap_Aaddress, amountApproved)
        .send({
          from: CurrentAccount,
          //gasLimit: hexValue,
          //to: tokenAddress,
          data: approveFunction.encodeABI(),
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 1.5),
        });

      return {code: "0"};
    } catch (error) {
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }

      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = i18n.t("ido.message");
      }
      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }

      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };
    }
  },
  // 再资
  async depositInterestAndReward(
    tokenAddress,
    amountADesired,
    CurrentAccount,
    _decimals,
    swap_Aaddress
  ) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/polygon.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      // 创建合约对象
      //   console.log(Factory.abi)

      const amountApproved = big(amountADesired)
        .times(10 ** _decimals)
        .toFixed();
      //   console.log(amountApproved)
      const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);
      //  console.log(tokenAddress)
      //  console.log(tokebContract)
      const approveFunction = tokebContract.methods.depositInterestAndReward(swap_Aaddress); // 替换为您要授权的代币数量
      //   console.log(approveFunction)
      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      //  预估
      const hexValue = await approveFunction.estimateGas({
        from: CurrentAccount,
        to: tokenAddress,
        data: approveFunction.encodeABI(),
      });
      //   console.log(hexValue);

      await tokebContract.methods
        .depositInterestAndReward(swap_Aaddress)
        .send({
          from: CurrentAccount,
          //gasLimit: hexValue,
          //to: tokenAddress,
          data: approveFunction.encodeABI(),
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 1.5),
        });

      return {code: "0"};
    } catch (error) {
      console.log(error)
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }
      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = i18n.t("ido.message");
      }
      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }

      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };
    }
  },
  // 领取
  async withdrawInterestAndReward(
    tokenAddress,
    amountADesired,
    CurrentAccount,
    _decimals
  ) {
    try {
      //   let position = "";
      //  console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/polygon.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      // 创建合约对象
      //    console.log(Factory.abi)

      const amountApproved = big(amountADesired)
        .times(10 ** _decimals)
        .toFixed();
      //   console.log(amountApproved)
      const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);
      //   console.log(tokenAddress)
      // console.log(tokebContract)
      const approveFunction = tokebContract.methods.withdrawInterestAndReward(); // 替换为您要授权的代币数量
      //  console.log(approveFunction)
      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      //  预估
      const hexValue = await approveFunction.estimateGas({
        from: CurrentAccount,
        to: tokenAddress,
        data: approveFunction.encodeABI(),
      });
      //   console.log(hexValue);

      await tokebContract.methods
        .withdrawInterestAndReward()
        .send({
          from: CurrentAccount,
          //gasLimit: hexValue,
          //to: tokenAddress,
          data: approveFunction.encodeABI(),
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 1.5),
        });

      return {code: "0"};
    } catch (error) {
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }
      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = i18n.t("ido.message");
      }
      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }

      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };
    }
  },
  // 领取2
  async withdrawInterestAndRewardToken(
    tokenAddress,
    amountADesired,
    CurrentAccount,
    _decimals,
    swap_Aaddress
  ) {
    try {
      //   let position = "";
      //  console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/polygon.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      // 创建合约对象
      //    console.log(Factory.abi)

      const amountApproved = big(amountADesired)
        .times(10 ** _decimals)
        .toFixed();
      //   console.log(amountApproved)
      const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);
      //   console.log(tokenAddress)
      // console.log(tokebContract)
      const approveFunction = tokebContract.methods.withdrawInterestAndRewardToken(swap_Aaddress); // 替换为您要授权的代币数量
      //  console.log(approveFunction)
      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      //  预估
      const hexValue = await approveFunction.estimateGas({
        from: CurrentAccount,
        to: tokenAddress,
        data: approveFunction.encodeABI(),
      });
      //   console.log(hexValue);

      await tokebContract.methods
        .withdrawInterestAndRewardToken(swap_Aaddress)
        .send({
          from: CurrentAccount,
          //gasLimit: hexValue,
          //to: tokenAddress,
          data: approveFunction.encodeABI(),
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 1.5),
        });

      return {code: "0"};
    } catch (error) {
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }
      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = i18n.t("ido.message");
      }
      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }

      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };
    }
  },
  // 绑定
  async bind(tokenAddress, amountADesired, CurrentAccount, _decimals) {
    try {
      //   let position = "";
      //  console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/polygon.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      // 创建合约对象
      //  console.log(Factory.abi)
      //     const number = amountADesired;
      //     const roundedNumber = Math.floor(number * 100000) / 100000 - 0.00001;

      //     console.log(roundedNumber); // 输出 10.4324

      // const amountApproved = big(roundedNumber)
      //   .times(10 ** _decimals)
      //   .toFixed();
      //   console.log(amountApproved)
      const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);
      //   console.log(tokenAddress)
      //console.log(tokebContract)
      const approveFunction = tokebContract.methods.bind(amountADesired); // 替换为您要授权的代币数量
      // console.log(approveFunction)
      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      //  预估
      const hexValue = await approveFunction.estimateGas({
        from: CurrentAccount,
        to: tokenAddress,
        data: approveFunction.encodeABI(),
      });
      //  console.log(hexValue);

      await tokebContract.methods
        .bind(amountADesired)
        .send({
          from: CurrentAccount,
          //gasLimit: hexValue,
          //to: tokenAddress,
          data: approveFunction.encodeABI(),
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 1.5),
        });

      return {code: "0"};
    } catch (error) {
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }
      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = i18n.t("ido.message");
      }
      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }

      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };
    }
  },

  async burnDepositToken(tokenAddress, CurrentAccount, _decimals, aavetoken, slidervalue, value, swap_Aaddress) {
    try {
      //   let position = "";
      //  console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/burn.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);
      //   console.log(tokenAddress)
      //console.log(tokebContract)
      const amountApproved = big(value)
        .times(10 ** _decimals)
        .toFixed();
      console.log(aavetoken, swap_Aaddress, slidervalue, amountApproved, 'burnDepositToken')
      const approveFunction = tokebContract.methods.burnDepositToken(aavetoken, swap_Aaddress, slidervalue, amountApproved); // 替换为您要授权的代币数量
      // console.log(approveFunction)
      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      //  预估
      const hexValue = await approveFunction.estimateGas({
        from: CurrentAccount,
        to: tokenAddress,
        data: approveFunction.encodeABI(),
      });
      //  console.log(hexValue);

      await tokebContract.methods
        .burnDepositToken(aavetoken, swap_Aaddress, slidervalue, amountApproved)
        .send({
          from: CurrentAccount,
          //gasLimit: hexValue,
          //to: tokenAddress,
          data: approveFunction.encodeABI(),
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 1.5),
        });

      return {code: "0"};
    } catch (error) {
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }
      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = i18n.t("ido.message");
      }
      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }

      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };
    }
  },

  async getMyInvestAmount(currentAddress, ContractAddress, swap_Aaddress) {
    try {

      let Factory;
      Factory = require("../json/abi/polygon.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例

      const balance = await contract.getMyInvestAmount(swap_Aaddress, currentAddress)
      var datas
      datas = big(balance.toString())
        .div(10 ** 18)
        .toFixed();

      return {code: "0", data: datas};
    } catch (error) {
      console.log('error');
    }
  },

};
